<template>
  <Portlet
    title="Home Office Report"
    icon="table"
  >
    <div slot="buttons">
      <button
        v-if="homeOfficeReportResult && homeOfficeReportResult.length > 0"
        class="btn btn-sm btn-primary ml-2 float-right"
        :disabled="exportLoading"
        @click="excelExport"
      >
        <font-awesome-icon
          v-if="exportLoading"
          :class="[{ 'fa-spin': exportLoading }]"
          icon="sync-alt"
        />
        <template v-else>
          <font-awesome-icon
            class="mr-1"
            icon="file-export"
          />
          <span>
            {{ "Export Excel" }}
          </span>
        </template>
      </button>
    </div>
    <LoadingPlaceholder v-if="reportData" />
    <template v-if="reportData">
      <div class="progress mt-3">
        <div
          class="progress-bar"
          role="progressbar"
          :style="{width: reportData.percentage + '%'}"
          :aria-valuenow="reportData.percentage"
          aria-valuemin="0"
          aria-valuemax="100"
        >
          {{ reportData.percentage + '%' }}
        </div>
      </div>
    </template>
    <div
      v-else
      class="homeOfficeReport"
    >
      <template>
        <form>
          <div class="form-group col-12 mt-3">
            <label>{{ $t("from") }}</label>
            <date-picker
              v-model="selectedDateFrom"
              :editable="true"
              :clearable="false"
              type="date"
              lang="en"
              format="DD.MM.YYYY"
              value-type="date"
              :first-day-of-week="1"
              class="ml-3"
            />
            <label class="ml-4">{{ $t("to") }}</label>
            <date-picker
              v-model="selectedDateTo"
              :editable="true"
              :clearable="false"
              type="date"
              lang="en"
              format="DD.MM.YYYY"
              value-type="date"
              :first-day-of-week="1"
              class="ml-3"
            />
          </div>
        </form>
        <button
          slot="button"
          class="btn btn-primary mt-4 mb-5"
          :disabled="!selectedDateFrom || !selectedDateTo"
          @click="generateReport()"
        >
          <font-awesome-icon
            class="mr-2"
            icon="file-alt"
          />Generate Report
        </button>
      </template>
      <div v-if="homeOfficeReportResult && homeOfficeReportResult.length > 0">
        <div class="mb-4">
          <Grid
            :ref="kgm_ref"
            :style="{ height: 'auto' }"
            :data-items="kgm_computedGridItems(homeOfficeReportResult)"
            :columns="kgm_responsiveColumns()"
            :filterable="true"
            :filter="kgm_filter"
            :pageable="kgm_pagable"
            :page-size="kgm_take"
            :skip="kgm_skip"
            :take="kgm_take"
            :total="kgm_allGridItems(homeOfficeReportResult)"
            :sortable="{
              allowUnsort: kgm_allowUnsort,
              mode: kgm_sortMode,
            }"
            :sort="kgm_sort"
            selected-field="selected"
            @rowclick="kgm_gridOnRowClick"
            @filterchange="kgm_gridFilterChange"
            @pagechange="kgm_gridPageChange"
            @sortchange="kgm_gridSortChange"
          >
            <div
              slot="cellFilter"
              slot-scope="{ props, methods }"
              class="input-group"
            >
              <input
                ref="cellFilter"
                :value="props.value"
                type="text"
                class="form-control mr-2 rounded-right"
                placeholder="please enter a value"
                @input="
                  (event) => {
                    methods.change({
                      operator: 'contains',
                      field: props.field,
                      value: event.target.value,
                      syntheticEvent: event,
                    });
                  }
                "
              >
              <div
                v-if="props.value"
                class="input-group-append"
              >
                <button
                  class="btn btn-light border rounded"
                  @click="
                    kgm_resetInput('cellFilter', (event) => {
                      methods.change({
                        operator: '',
                        field: '',
                        value: '',
                        syntheticEvent: event,
                      });
                    })
                  "
                >
                  <font-awesome-icon
                    class="gray"
                    icon="times"
                  />
                </button>
              </div>
            </div>
          </Grid>
        </div>
      </div>
      <template v-else-if="homeOfficeReportResult && homeOfficeReportResult.length == 0">
        <div>
          {{ $t('noDataAvailable') }}
        </div>
      </template>
    </div>
  </Portlet>
</template>

<script>
import { dateTimeMixin } from '@/mixins/dateTimeMixin.js';
import DatePicker from 'vue2-datepicker';
import { urlParseMixin } from '@/mixins/urlParseMixin';
import { authenticationMixin } from '@/mixins/authenticationMixin';
import { kendoGridMixin } from '@/mixins/kendoGridMixin.js';

export default {
  name: 'HomeOfficeReport',
  components: {
    DatePicker
  },
  mixins: [
    dateTimeMixin,
    urlParseMixin,
    authenticationMixin,
    kendoGridMixin,
  ],
  data () {
    return {
      loading: false,
      selectedDateFrom: this.moment().subtract(1, 'months').startOf('month'),
      selectedDateTo: this.moment().subtract(1, 'months').endOf('month'),
      homeOfficeReportResult: null,
      progress: 0,
      createExcel: true,
      exportLoading: false,
      reportInterval: null,
      reportData: null,
      kgm_take: 100,
      kgm_columns: [
        {
          field: "employeeNumber",
          filterable: true,
          filter: "text",
          title: this.$t("homeOfficeReport.employeeNumber"),
          filterCell: "cellFilter",
        },
        {
          field: "employeeName",
          filterable: true,
          filter: "text",
          title: this.$t("homeOfficeReport.employeeName"),
          filterCell: "cellFilter",
        },
        {
          field: "employeeNumberOfDays",
          filterable: true,
          filter: "text",
          title: this.$t("homeOfficeReport.employeeNumberOfDays"),
          filterCell: "cellFilter",
        }
      ],
    }
  },
  beforeDestroy () {
    this.cancelInterval();
  },
  methods: {
    excelExport () {
      this.createExcel = true;
      this.exportReport();
    },
    createInterval () {
      this.reportData = { percentage: 0 };
      this.reportInterval = window.setInterval(() => {
        this.getReportProgress();
      }, 2000);
    },
    cancelInterval () {
      this.reportData = null;
      window.clearInterval(this.reportInterval);
      this.reportInterval = null;
      this.reportData = null;
    },
    getReportProgress () {
      var user = this.authenticationGetUser();
      var email = user.details.profile.email;
      this.axios
        .get(`/Issue/GetHomeOfficeReportData?user=${email}`)
        .then((response) => {
          if (response.status == 200 && response.data) {
            this.reportData = response.data;
            if (this.reportData.value) {
              this.homeOfficeReportResult = this.reportData.value;
              this.cancelInterval();
            } else if (this.reportData.error) {
              this.cancelInterval();
            }
          } else {
            this.reportData = null;
            this.cancelInterval();
          }
        });
    },
    async generateReport () {
      var user = this.authenticationGetUser();
      var email = user.details.profile.email; 
      this.issueResult = {};
      var req = {
        startDate: this.selectedDateFrom,
        endDate: this.selectedDateTo,
        user: email,
      };

      await this.axios.post(`/Issue/GetHomeOfficeReport`, req).then(() => {
        this.createInterval();
      });
    },
    exportReport () {
      this.exportLoading = true;
      let request = {
        value: this.homeOfficeReportResult,
        createExcel: this.createExcel,
        startDate: this.selectedDateFrom,
        endDate: this.selectedDateTo,
      };

      this.axios
        .post(`/Issue/ExportHomeOfficeReport`, request)
        .then((response) => {
          if (response) {
            if (response && response.data) {
              for (var item of response.data) {
                const link = document.createElement("a");
                link.href =
                  "data:application/octet-stream;charset=utf-8;base64," +
                  item.base64;
                link.target = "_blank";
                link.download = item.filename;
                link.click();
              }
            }
          }
        })
        .finally(() => {
          this.exportLoading = false;
        });
    },
  },
}
</script>